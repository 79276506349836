<template>
  <div v-if="error" class="w3-padding w3-panel w3-pale-red w3-leftbar w3-border-red">
    {{ error }}
  </div>
  <div v-if="formattedDocuments" ref="messages" class="imessage">
    <template v-for="doc in formattedDocuments" :key="doc.id">
      <span v-if="doc.name === user.displayName" class="created-at-me">{{
          doc.createdAt
      }}</span>
      <span v-else class="created-at-me">{{ doc.createdAt }}</span>

      <p v-if="doc.name === user.displayName" class="from-me">
        {{ doc.message }}
      </p>
      <p v-else class="from-them">
        <span class="name">{{ doc.name }}: </span>{{ doc.message }}
      </p>
    </template>
  </div>
</template>

<script>
import { computed, onUpdated, onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  query,
  getDocs,
  orderBy,
  onSnapshot,
  limit
} from 'firebase/firestore';

import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { enUS } from 'date-fns//locale'


export default {
  setup(props) {
    const error = ref('');
    const documents = ref([]);
    const user = useUserStore();
    let unsubscribe;
    let time;
    // Initial subription
    subscribe4Chat();

    // format timestamp
    const formattedDocuments = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          if (doc.createdAt) {
            time = formatDistanceToNow(doc.createdAt.toDate(), {
              locale: getLanguage()
            });
          } else {
            time = new Date();
          }
          return { ...doc, createdAt: time };
        });
      }
    });
    // auto-scroll to bottom of messages
    const messages = ref(null);
    onMounted(() => {
      subscribe4Chat();
      messages.value.scrollTop = messages.value.scrollHeight;
    });

    onUpdated(() => {
      messages.value.scrollTop = messages.value.scrollHeight;

    })

    function getLanguage() {
      if (navigator.language.slice(0, 2) == "de") {
        return (de)
      } else {
        return (enUS)
      }
    }

    function subscribe4Chat() {
      // subscribe for chat
      const colRefChat = collection(db, 'chat');
      const qEvents = query(colRefChat, orderBy('createdAt'), limit(100));

      unsubscribe = onSnapshot(
        qEvents,
        (snapshot) => {
          documents.value = [];
          snapshot.docs.forEach((doc, index) => {
            documents.value.push({
              ...doc.data(),
              id: doc.id
            });
          });
        },
        (err) => {
          console.log('error with db: ' + err.message);
          error.value =
            'Es gab einen Fehler mit der Datenbank. Bitte versuch es später noch einmal';
        }
      );
    }

    return {
      error,
      documents,
      formattedDocuments,
      messages,
      user
    };
  }
};
</script>

<style scoped>
[class^='created-at'] {
  display: block;
  color: #999;
  font-size: 12px;
  margin-top: 4px;
}

.created-at-me {
  text-align: right;
}

.name {
  font-weight: bold;
  margin-right: 6px;
}

.imessage {
  border-radius: 12px;
  border: 1px solid #ccc;
  max-height: 600px;
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 1rem auto 1rem;
  max-width: 100%;
  padding: 0.5rem 1.5rem;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: '';
  height: 1rem;
  position: absolute;
}

p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^='from-'] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-me~p.from-me {
  margin: 0.25rem 0 0;
}

p.from-me~p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-me~p.from-me:last-child {
  margin-bottom: 0.5rem;
}

p.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^='from-'].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^='from-'].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}
</style>