<template>
  <form>
    <textarea
      :placeholder="t('chat_message')"
      v-model="message"
      @keypress.enter.prevent="handleSubmit"
    ></textarea>
    <div v-if="error" class="error">{{ error }}</div>
  </form>
</template>

<script>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';

// firebase imports
import { db } from '@/firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

import { useI18n } from 'vue-i18n';
import Translations from '@/translations/newchatforum.json';

export default {
  setup() {
    const error = ref('');

    // get Stores
    const user = useUserStore();

    // Prepare translations
    const { t } = useI18n({
      messages: Translations
    });

    // refs
    const message = ref('');

    const handleSubmit = async () => {
      await addDoc(collection(db, 'chat'), {
        name: user.displayName,
        message: message.value,
        createdAt: serverTimestamp()
      })
        .then(() => {
          console.log('chat - successful');
          message.value = '';
        })
        .catch((err) => {
          console.log('chat - error');
          console.log(err.code);
          error.value =
            'Beim Senden der Chat Nachricht ist ein Fehler aufgetreten (' +
            err.code +
            ')';
        });
    };
    return { t, message, handleSubmit, error };
  }
};
</script>

<style scoped>
form {
  margin: 1px;
}
textarea {
  width: 100%;
  background-color: rgba(250, 246, 246, 0.975);
  border: 1px solid #ccc;
  max-width: 100%;
  margin-bottom: 6px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: inherit;
  outline: none;
}
</style>
