import { defineStore } from 'pinia'
// Firebase Imports
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from "firebase/auth";
import { auth } from "@/firebase/config";
import { db } from '@/firebase/config';
import { doc, getDoc, setDoc, serverTimestamp, updateDoc, increment, Timestamp } from "firebase/firestore";


export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: null,
        firstname: '',
        lasttname: '',
        displayName: '',
        email: '',
        username: '',
        champion: '',
        role: '',
        isLoggedIn: false,
        uid: ''
    }),
    getters: {
        getUser: (state) => {
            return state.user;
        },
    },

    getters: {
        getCurrentUser: () => auth.currentUser,
    },

    actions: {
        login(data) {
            return signInWithEmailAndPassword(auth, data.email, data.password);
        },
        logout() {
            signOut(auth)
                .then(() => {
                    console.log("User Store - logout successful");
                })
                .catch((error) => {
                    console.log("User Store - logout error: " + error);
                });
        },
        signup(data) {
            return new Promise((resolve, reject) => {
                createUserWithEmailAndPassword(auth, data.email, data.password)
                    .then(async(userCredentials) => {
                        // Signed in
                        this.user = userCredentials.user;
                        // Create recored with user details
                        setDoc(doc(db, "userCollection", userCredentials.user.uid), {
                            displayName: data.displayname,
                            firstname: data.firstname,
                            lastname: data.lastname,
                            email: data.email,
                            firebaseUserID: userCredentials.user.uid,
                            role: "player",
                            champion: "",
                            thischampion: "",
                            createdAt: serverTimestamp(),
                            betData: {
                                numberOfBetsForCurrentPlayer: 0,
                                numberOfCorrectTipsForCurrentPlayer: 0,
                                amountOfWinForCurrentPlayer: 0,
                                amountOfBets: 0,
                                balanceForCurrentPlayer: 0
                            }

                        });
                        resolve(userCredentials);

                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            });
        },
        initializeAuthentication() {
            const user = this.getCurrentUser;

            if (user) {
                this.user = user;
                this.isLoggedIn = true;
            } else {
                this.user = null;
                this.isLoggedIn = false;
            }

            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.user = user;
                    this.isLoggedIn = true;
                    const docRef = doc(db, "userCollection", user.uid);
                    // increase usage counter
                    updateDoc(docRef, {
                            logins: increment(1),
                            lastLogin: serverTimestamp()
                        })
                        .catch((err) => {
                            console.log("setUser increment user count error : " + err.message);
                        });;
                    // read user data
                    getDoc(docRef)
                        .then((snapshot) => {
                            this.firstname = snapshot.data().firstname;
                            this.lasttname = snapshot.data().lastname;
                            this.email = snapshot.data().email;
                            this.role = snapshot.data().role;
                            this.displayName = snapshot.data().displayName;
                            this.champion = snapshot.data().champion
                            this.uid = snapshot.id;
                        })
                        .catch((err) => {
                            console.log("setUser read user data error : " + err.message);
                            this.user = null;
                            this.isLoggedIn = false;
                        });

                } else {
                    this.user = null;
                    this.uid = '';
                    this.isLoggedIn = false;
                    this.firstname = '';
                    this.lasttname = '';
                    this.email = '';
                    this.role = '';
                    this.displayName = '';
                }
            });
        },

    }
})