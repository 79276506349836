<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <article class="message box p-0 my-6 is-primary my-4 has-shadow">
        <p class="message-header">{{ t('welcome') }}</p>
        <div class="message-body">
          <p v-html="t('message')"></p>
        </div>
        <div class="buttons is-centered">
          <a
            class="button is-success"
            v-if="!user.isLoggedIn"
            @click="handleSignup"
          >
            {{ t('signup') }}
          </a>
          <a
            class="button is-info"
            v-if="!user.isLoggedIn"
            @click="handleLogin"
          >
            {{ t('login') }}
          </a>
        </div>
      </article>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Translations from '@/translations/welcome.json';

export default {
  setup() {
    const user = useUserStore();
    const router = useRouter();

    // Prepare translations
    const { t } = useI18n({
      messages: Translations
    });

    // signup new user
    const handleSignup = () => {
      console.log('NavBar Signup');
      router.push({ name: 'Signup' });
    };
    // login existing user
    const handleLogin = () => {
      console.log('NavBar Login');
      router.push({ name: 'Login' });
    };

    return { user, t, handleSignup, handleLogin };
  }
};
</script>

<style>
</style>