<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <article class="message box p-0 is-primary my-6 has-shadow">
        <p class="message-header">{{ t('registration') }}</p>
        <div class="block p-4">
          <form class="message is-primary">
            <div class="block">
              {{ t('message') }}
            </div>
            <div class="block">
              <strong>{{ t('terms_headline') }}</strong>
            </div>
            <div class="block" v-html="t('terms')"></div>
            <!-- Display name -->
            <div class="field">
              <label class="label">{{ t('displayname') }} </label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="game name"
                  v-model="displayname"
                />
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <!-- First name -->
                <div class="field">
                  <label class="label">{{ t('firstname') }} </label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="fistname"
                      v-model="firstname"
                    />
                  </div>
                </div>
                <!-- Last name -->
                <div class="field">
                  <label class="label">{{ t('lastname') }} </label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="lastname"
                      v-model="lastname"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- email -->
            <div class="field">
              <label class="label">{{ t('email') }} </label>
              <div class="control">
                <input
                  class="input is-normal"
                  type="email"
                  placeholder="email"
                  v-model="email"
                />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="label">{{ t('password') }} </label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="password"
                  v-model="password"
                />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="label">{{ t('confirm') }} </label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="repeat password"
                  v-model="password2"
                />
              </div>
            </div>
            <!-- error message -->
            <article v-if="error" class="message is-danger">
              <div class="message-body">
                {{ error }}
              </div>
            </article>
            <!-- Submit  -->
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <a class="button is-primary" @click="handleSubmit">
                  {{ t('submit') }}
                </a>
              </p>
            </div>
          </form>
        </div>
      </article>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Translations from '@/translations/signup.json';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);

    // Prepare translations
    const { t } = useI18n({
      messages: Translations
    });

    // data field
    const email = ref('');
    const password = ref('');
    const password2 = ref('');
    const displayname = ref('');
    const firstname = ref('');
    const lastname = ref('');
    // user store
    const user = useUserStore();
    // activate router
    const router = useRouter();

    const handleSubmit = () => {
      // check if password is OK
      if (password.value != password2.value) {
        error.value =
          'The entered password does not match the confirmation entry';
      } else {
        // call store to manage signup
        const data = {
          displayname: displayname.value,
          firstname: firstname.value,
          lastname: lastname.value,
          email: email.value,
          password: password.value
        };
        user
          .signup(data)
          .then(() => {
            error.value = '';
            router.push({ name: 'Home' });
          })
          .catch((err) => {
            console.log('Error: ' + err.code + ' / ' + error.message);
            switch (err.code) {
              case 'auth/invalid-email':
                error.value = 'Invalid email';
                break;
              case 'auth/weak-password':
                error.value =
                  'Password should be at least 6 characters (auth/weak-password).';
                break;
              case 'email-already-in-use':
                error.value =
                  'The email address is already in use by another account.';
              default:
                error.value = 'Registration failed. Please try again later';
                break;
            }
          });
      }
    };

    return {
      handleSubmit,
      email,
      password,
      password2,
      displayname,
      firstname,
      lastname,
      error,
      t
    };
  }
};
</script>

<style>
</style>