import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/UserStore';

import HomeView from '@/views/HomeView.vue'
import Signup from '@/views/admin/Signup.vue'
import Login from '@/views/admin/Login.vue'
import Teams from '@/views/admin/Teams.vue'
import Games from '@/views/admin/Games.vue'
import Players from '@/views/admin/Players.vue'


const routes = [{
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    // Admin fuctions
    {
        path: '/admin/teams',
        name: 'Teams',
        component: Teams,
        meta: { requireAuth: true },

    },
    {
        path: '/admin/games',
        name: 'Games',
        component: Games,
        meta: { requireAuth: true },
    },
    {
        path: '/admin/players',
        name: 'Players',
        component: Players,
        meta: { requireAuth: true },
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach(async(to, from, next) => {
    const user = useUserStore();
    const requireAuth = to.matched.some((route) => route.meta.requireAuth);

    if (requireAuth && !user.isLoggedIn) {
        next({ name: "Home" });
    } else {
        next();
    }

});
export default router