<template>
  <Navbar />
  <div v-if="!config.loading">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Navbar from '@/views/NavigationBar.vue';
import Footer from '@/views/Footer.vue';

// import store
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

export default {
  components: { Navbar, Footer },

  setup() {
    const config = useConfiguraitonStore();
    config.getConfiguration();
    return { config };
  }
};
</script>
