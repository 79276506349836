<template>
  <nav :class="{ 'is-transformed': !showNavbar }" role="navigation" class="navbar has-shadow">
    <div class="navbar-brand">
      <a class="navbar-item" @click="handleHome">
        <img src="@/assets/logo.png" />
      </a>
      <div class="navbar-item p-0 m-0">
        <p class="control is-size-2 has-text-info">|</p>
      </div>
      <div class="navbar-item">
        <div class="field is-grouped">
          <p class="control">
            Hallo <strong>{{ user.displayName }}</strong>
          </p>
        </div>
      </div>
      <div class="navbar-item p-0 m-0">
        <p class="control is-size-2 has-text-info">|</p>
      </div>
      <div class="navbar-item">
        <p v-if="user.role" class="control is-size-7">
          {{ t('role') }} <br />
          {{ user.role }}
        </p>
      </div>

      <a :aria-expanded="isActive" :class="{ 'is-active': isActive }" role="button" class="navbar-burger"
        aria-label="menu" data-target="collapse" @click="isActive = !isActive">
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div id="collapse" :class="{ 'is-active': isActive }" class="navbar-menu is-paddingless">
      <div class="navbar-end">
        <div class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item p-0 m-0" v-if="user.user"></div>
          </div>
        </div>

        <div class="navbar-item">
          <div class="navbar-item has-dropdown is-hoverable" v-if="user.role === 'admin'">
            <a class="navbar-link"> Admin </a>

            <div class="navbar-dropdown">
              <a class="navbar-item" @click="handleAdminTeams">
                {{ t('teams') }}
              </a>
              <a class="navbar-item" @click="handleAdminGames">
                {{ t('games') }}
              </a>
              <a class="navbar-item" @click="handleAdminPlayers">
                {{ t('players') }}
              </a>
            </div>
          </div>

          <div class="buttons">
            <a class="button is-success" v-if="!user.isLoggedIn" @click="handleSignup">
              {{ t('signup') }}
            </a>
            <a class="button is-info" v-if="user.isLoggedIn" @click="handleLogout">
              {{ t('logout') }}
            </a>
            <a class="button is-info" v-if="!user.isLoggedIn" @click="handleLogin">
              {{ t('login') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Translations from '@/translations/navigationbar.json';
import throttle from 'lodash/throttle';

export default {
  data() {
    return {
      isActive: false,
      showNavbar: true,
      lastScrollPosition: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', throttle(this.closeMenu, 500));
      window.addEventListener('scroll', throttle(this.hideNav, 250));
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.closeMenu);
    window.removeEventListener('scroll', this.hideNav);
  },
  methods: {
    closeMenu() {
      this.isActive = false;
    },
    hideNav() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) return;
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60)
        return;
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
      setTimeout(this.closeMenu, 250);
    }
  },
  setup() {
    const router = useRouter();
    const user = useUserStore();

    // Prepare translations
    const { t } = useI18n({
      messages: Translations
    });

    // signup new user
    const handleSignup = () => {
      console.log('NavBar Signup');
      router.push({ name: 'Signup' });
    };
    // login existing user
    const handleLogin = () => {
      console.log('NavBar Login');
      router.push({ name: 'Login' });
    };
    // logout current user
    const handleLogout = () => {
      console.log('NavBar Logout');
      user.logout();
      router.push({ name: 'Home' });
    };
    // logout current user
    const handleHome = () => {
      console.log('NavBar Home');
      router.push({ name: 'Home' });
    };
    // call admin function for teams
    const handleAdminTeams = () => {
      console.log('NavBar Admin Teams');
      router.push({ name: 'Teams' });
    };
    // call admin function for games
    const handleAdminGames = () => {
      console.log('NavBar Admin Teams');
      router.push({ name: 'Games' });
    };
    // call admin function for players
    const handleAdminPlayers = () => {
      console.log('NavBar Admin Players');
      router.push({ name: 'Players' });
    };
    return {
      user,
      handleLogin,
      handleLogout,
      handleSignup,
      handleHome,
      handleAdminTeams,
      handleAdminGames,
      handleAdminPlayers,
      t
    };
  }
};
</script>

<style>
.navbar {
  transition: transform 200ms ease-out;

  &.is-transformed {
    transform: translate3d(0, -100%, 0);
  }
}
</style>