<template>
  <div class="block mx-4 mt-4">
    <!-- error message -->
    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>

    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Create Team</p>
      <div class="block p-4">
        <div class="columns">
          <div class="column is-two-thirds">
            <form class="message is-primary">
              <!-- Team name -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Name</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="text"
                        placeholder="Name of team"
                        v-model="newTeam.name"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <!-- Text  -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Text</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="text"
                        placeholder="Text"
                        v-model="newTeam.text"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Flag</label>
                </div>
                <div class="field-body">
                  <div v-if="selectedFlag" class="field">
                    <figure class="image is-48x48">
                      <img :src="selectedFlag" />
                    </figure>
                  </div>
                </div>
              </div>
              <!-- Create new team  -->
              <div class="field is-grouped is-grouped-centered">
                <p class="control">
                  <a class="button is-primary" @click="handleNewTeam">
                    Create new team
                  </a>
                </p>
              </div>
            </form>
          </div>
          <div class="column is-one-third">
            <ul class="flex-container wrap">
              <div v-for="flag in flags" :key="flag.url">
                <li class="flex-item">
                  <figure class="image is-32x32">
                    <img :src="flag.url" @click="selectedFlag = flag.url" />
                  </figure>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </article>
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Teams</p>
      <div class="block p-4">
        <section id="my-table">
          <div class="table-container">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Flag</th>
                  <th>Text</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <!-- Liste -->
                <tr v-for="team in teams" :key="team.id">
                  <td>{{ team.name }}</td>
                  <td>
                    <figure class="image is-48x48">
                      <img :src="team.flag" />
                    </figure>
                  </td>
                  <td>{{ team.text }}</td>
                  <td class="has-text-right">
                    <i
                      @click="editTeam(team.id)"
                      class="material-icons is-info button mx-2"
                      >edit</i
                    >
                    <i
                      @click="deleteTeam(team.id)"
                      class="material-icons is-danger button"
                      >delete</i
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </article>
    <article
      v-if="selectedTeam"
      class="message box p-0 is-primary my-6 has-shadow"
    >
      <p class="message-header">Edit team</p>
      <div class="block p-4">
        <form class="message is-primary">
          <!-- Team name -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="team name"
                    v-model="selectedTeamData.name"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Text  -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Text</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="description"
                    v-model="selectedTeamData.text"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Show flag -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Flag</label>
            </div>
            <div class="field-body">
              <div class="field">
                <figure class="image is-48x48">
                  <img :src="selectedTeamData.flag" />
                </figure>
              </div>
            </div>
          </div>

          <!-- Submit  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-primary" @click="handleSubmit"> Submit </a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </div>
</template>


<script>
// vue imports
import { ref } from 'vue';

// firebase imports
import { db, storage } from '@/firebase/config';
import {
  collection,
  doc,
  query,
  onSnapshot,
  updateDoc,
  deleteDoc,
  addDoc,
  orderBy
} from 'firebase/firestore';
import { ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);

    // get Stores
    const config = useConfiguraitonStore();

    // All teams read from firebase
    const teams = ref([]);
    const selectedTeam = ref('');
    const selectedTeamData = ref('');

    // All flags read from storage
    const flags = ref([]);
    const selectedFlag = ref('');

    // New team
    const newTeam = ref('');
    newTeam.value = {
      name: '',
      text: '',
      flag: ''
    };

    // read flags from storage
    const listRef = storageRef(storage, config.configuration.Flags);
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          // Get metadata properties
          getDownloadURL(itemRef)
            .then((url) => {
              flags.value.push({ url: url });
            })
            .catch((err) => {
              error.value = err;
            });
        });
      })
      .catch((err) => {
        error.value = err;
      });

    // Read team data
    console.log('Read teams');
    const colRef = query(
      collection(db, config.configuration.Teams),
      orderBy('name')
    );
    onSnapshot(
      colRef,
      (snapshot) => {
        teams.value = [];
        snapshot.docs.forEach((doc) => {
          teams.value.push({ ...doc.data(), id: doc.id }) - 1;
        });
        console.log('read teams: ' + teams.value.length + ' teams');
      },
      (err) => {
        error.value = err;
      }
    );

    // Edit team
    const editTeam = (idOfSelectedTeam) => {
      console.log('Edit Team: ', idOfSelectedTeam);
      selectedTeamData.value = teams.value.find(
        ({ id }) => id == idOfSelectedTeam
      );
      selectedTeam.value = selectedTeamData.value.id;
      console.log(selectedTeam.value);
    };

    // Delete team
    const deleteTeam = (idOfSelectedTeam) => {
      console.log('Delete Team: ', idOfSelectedTeam);
      let docRef = doc(db, config.configuration.Teams, idOfSelectedTeam);
      deleteDoc(docRef)
        .then(() => {
          selectedTeam.value = '';
          selectedFlag.value = '';
        })
        .catch((err) => {
          error.value = err;
        });
    };

    // Create new Team
    const handleNewTeam = () => {
      console.log('Create new team');

      if (!newTeam.value.name) {
        error.value = 'Name of team is required';
      } else {
        if (!selectedFlag.value) {
          error.value = 'Please select a flag';
        } else {
          const docRef = addDoc(collection(db, config.configuration.Teams), {
            name: newTeam.value.name,
            flag: selectedFlag.value,
            text: newTeam.value.text
          })
            .then(() => {
              selectedTeam.value = '';
              selectedFlag.value = '';
              newTeam.value = {
                name: '',
                text: '',
                flag: ''
              };
            })
            .catch((err) => {
              error.value = err;
            });
        }
      }
    };

    // Update team data
    const handleSubmit = () => {
      let docRef = doc(
        db,
        config.configuration.Teams,
        selectedTeamData.value.id
      );

      updateDoc(docRef, {
        name: selectedTeamData.value.name,
        flag: selectedTeamData.value.flag,
        text: selectedTeamData.value.text
      })
        .then(() => {
          selectedTeam.value = '';
          selectedFlag.value = '';
        })
        .catch((err) => {
          error.value = err;
        });
    };

    return {
      flags,
      selectedFlag,
      teams,
      selectedTeam,
      selectedTeamData,
      newTeam,
      error,
      editTeam,
      deleteTeam,
      handleSubmit,
      handleNewTeam
    };
  }
};
</script>


<style scoped>
#my-table .table {
  background-color: whitesmoke;
}

.flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-item {
  margin: 1em;
}
</style>