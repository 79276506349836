<template>
  <div v-if="!user.isLoggedIn">
    <Welcome />
  </div>
  <div v-else><MainPage /></div>
</template>

<script>
// import UI
import NavigationBar from '@/views/NavigationBar.vue';
import Footer from '@/views/Footer.vue';
// Home page for user if not logged in
import Welcome from '@/views/Welcome.vue';
// Home page for user if logged in
import MainPage from '@/views/MainPage.vue';

// import store
import { useUserStore } from '@/stores/UserStore';

export default {
  components: { NavigationBar, Welcome, MainPage, Footer },
  setup() {
    const user = useUserStore();
    user.initializeAuthentication();
    return { user };
  }
};
</script>

<style>
</style>