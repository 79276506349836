<template>
  <div class="block mx-4 mt-4">
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Subscribed users</p>
      <div class="block p-4">
        <section id="my-table">
          <div class="table-container">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Firstname</th>
                  <th>Lastname</th>
                  <th>Displayname</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Signup</th>
                  <th>Last login</th>
                  <th># Login</th>
                  <th># Tips</th>
                  <th># Correct</th>
                  <th>€ Tips</th>
                  <th>€ Win</th>
                  <th>Balance</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <!-- Liste -->
                <tr v-for="user in users" :key="user.id">
                  <td>{{ user.firstname }}</td>
                  <td>{{ user.lastname }}</td>
                  <td>{{ user.displayName }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.role }}</td>
                  <td>{{ user.createdAt }}</td>
                  <td>{{ user.updateAt }}</td>
                  <td class="has-text-centered">{{ user.logins }}</td>
                  <td class="has-text-centered">
                    <span v-if="user.betData">{{ user.betData.numberOfBetsForCurrentPlayer }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="user.betData">{{ user.betData.numberOfCorrectTipsForCurrentPlayer }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="user.betData">{{ getFormattedAmount(user.betData.amountOfBets) }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="user.betData">
                      {{
                          getFormattedAmount(
                            user.betData.amountOfWinForCurrentPlayer
                          )
                      }}
                    </span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="user.betData">
                      {{
                          getFormattedAmount(user.betData.balanceForCurrentPlayer)
                      }}
                    </span>
                  </td>
                  <td>
                    <i @click="editUser(user.id)" class="material-icons is-info button">edit</i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </article>
    <article v-if="selectedUser" class="message box p-0 is-primary my-6 has-shadow">
      <p class="message-header">Edit user</p>
      <div class="block p-4">
        <form class="message is-primary">
          <!-- Firstname -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Firstname</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Firstname" v-model="selectedUserData.firstname" />
                </p>
              </div>
            </div>
          </div>
          <!-- Lastname -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Lastname</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Lastname" v-model="selectedUserData.lastname" />
                </p>
              </div>
            </div>
          </div>
          <!-- Ingame name -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ingame name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Ingame name" v-model="selectedUserData.displayName" />
                </p>
              </div>
            </div>
          </div>
          <!-- email -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">email</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="email" v-model="selectedUserData.email" disabled />
                </p>
              </div>
            </div>
          </div>
          <!-- role -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">role</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Role" v-model="selectedUserData.role" />
                </p>
              </div>
            </div>
          </div>

          <!-- error message -->
          <article v-if="error" class="message is-danger">
            <div class="message-body">
              {{ error }}
            </div>
          </article>
          <!-- Submit  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-primary" @click="handleSubmit"> Submit </a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </div>
</template>

<script>
// vue imports
import { ref } from 'vue';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);

    // All users read from firebase
    const users = ref([]);
    // Current user for update
    const selectedUser = ref('');
    const selectedUserData = ref('');

    // collection ref for users
    const colRef = collection(db, 'userCollection');

    // Read user data
    getDocs(colRef)
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          let x = users.value.push({ ...doc.data(), id: doc.id }) - 1;
          if ('createdAt' in users.value[x]) {
            users.value[x].createdAt = users.value[x].createdAt
              .toDate()
              .toLocaleDateString('de-DE');
          }
          if ('lastLogin' in users.value[x]) {
            users.value[x].updateAt = users.value[x].lastLogin
              .toDate()
              .toLocaleDateString('de-DE');
          } else {
            users.value[x].updateAt = null;
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

    // Edit user
    const editUser = (idOfSelectedUser) => {
      console.log('Edit User: ', idOfSelectedUser);
      selectedUserData.value = users.value.find(
        ({ id }) => id == idOfSelectedUser
      );
      selectedUser.value = selectedUserData.value.id;
      console.log(selectedUser.value);
      console.log(selectedUserData.value);
    };

    // Update user data
    const handleSubmit = () => {
      let docRef = doc(db, 'userCollection', selectedUserData.value.id);

      updateDoc(docRef, {
        firstname: selectedUserData.value.firstname,
        lastname: selectedUserData.value.lastname,
        displayName: selectedUserData.value.displayName,
        role: selectedUserData.value.role,
        updateAt: serverTimestamp()
      }).then(() => {
        selectedUser.value = '';
      });
    };

    const getFormattedAmount = (amount) => {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(amount);
    };

    return {
      users,
      selectedUser,
      selectedUserData,
      error,
      editUser,
      handleSubmit,
      getFormattedAmount
    };
  }
};
</script>


<style scoped>
#my-table .table {
  background-color: whitesmoke;
}
</style>