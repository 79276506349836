<template>
  <article class="message box p-0 m-3 has-shadow">
    <div class="box has-text-centered has-background-primary-light">
      <div class="has-text-primary-dark has-text-centered title">
        {{ gameName }}
      </div>
    </div>
  </article>
  <!-- error message -->
  <article v-if="error" class="message is-danger">
    <div class="message-body">
      {{ error }}
    </div>
  </article>

  <div class="columns">
    <div class="column is-one-third">
      <!-- Bet -->
      <article
        class="message box p-0 is-warning m-3 has-shadow"
        v-if="overallGameData"
      >
        <p class="message-header">{{ t('articel_bet') }}</p>
        <div class="block p-4">
          <div class="columns">
            <div class="column is-one-third">
              <!-- Current open bet -->
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">{{ t('articel_bet_pot') }}</p>
                </header>
                <div class="card-content has-text-centered">
                  <p class="title has-text-danger">
                    {{ getFormattedAmount(overallGameData.totalOpenBalance) }}
                  </p>
                  <p class="is-size-7">
                    {{ t('articel_bet_by') }}
                    {{ overallGameData.totalOpenGames }}
                    {{ t('articel_bet_games') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-one-third">
              <!-- paid out bet -->
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">{{ t('articel_bet_wins') }}</p>
                </header>
                <div class="card-content has-text-centered">
                  <p class="title has-text-success">
                    {{ getFormattedAmount(overallGameData.totalPayoutBalance) }}
                  </p>
                  <p class="is-size-7">
                    {{ t('articel_bet_by') }}
                    {{ overallGameData.totalPayoutGames }}
                    {{ t('articel_bet_games') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-one-third">
              <!-- Number of tips -->
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">{{ t('articel_bet_tips') }}</p>
                </header>
                <div class="card-content has-text-centered">
                  <p class="title has-text-success">
                    {{ overallGameData.totalTips }}
                  </p>
                  <p class="is-size-7">
                    {{ t('articel_bet_by') }} {{ overallGameData.totalGames }}
                    {{ t('articel_bet_games') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <!-- Champion  -->
      <article class="message box p-0 is-link m-3 has-shadow">
        <p class="message-header">{{ t('champion') }}</p>
        <div class="block p-4">
          <div class="field has-addons has-addons-centered">
            <p class="control">
              <a class="button is-grey-ligh"> {{ t('champion_tip') }} </a>
            </p>
            <div v-if="config.configuration.championTip" class="select">
              <select v-model="user.champion" @change="championSelect($event)">
                <option v-for="team in teams" :key="team" v-bind:value="team">
                  {{ team.name }}
                </option>
              </select>
            </div>
            <div v-else class="control">
              <input
                class="input"
                type="text"
                v-model="user.champion.name"
                readonly
              />
            </div>
          </div>
        </div>
      </article>
      <!-- Winnerlist -->
      <article class="message box p-0 is-primary m-3 has-shadow">
        <p class="message-header">{{ t('articel_winnerlist') }}</p>
        <div class="block p-4">
          <section id="my-table">
            <div class="table-container">
              <table class="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>{{ t('articel_winnerlist_player') }}</th>
                    <th>{{ t('articel_winnerlist_tips') }}</th>
                    <th>{{ t('articel_winnerlist_correct_tips') }}</th>
                    <th>{{ t('articel_winnerlist_win') }}</th>
                    <th>{{ t('articel_winnerlist_champion') }}</th>
                    <th>{{ t('articel_winnerlist_balance') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Liste -->
                  <tr v-for="player in users" :key="player.id">
                    <td>{{ player.displayName }}</td>
                    <td>{{ player.betData.numberOfBetsForCurrentPlayer }}</td>
                    <td>
                      {{ player.betData.numberOfCorrectTipsForCurrentPlayer }}
                    </td>
                    <td>
                      {{
                        getFormattedAmount(
                          player.betData.amountOfWinForCurrentPlayer
                        )
                      }}
                    </td>
                    <td>
                      <figure v-if="player.champion" class="image is-32x32">
                        <img :src="player.champion.flag" />
                      </figure>
                    </td>

                    <td>
                      <p
                        :class="
                          isAmountPositiv(calculateBalance(player))
                            ? 'has-text-success'
                            : 'has-text-danger'
                        "
                      >
                        {{ getFormattedAmount(calculateBalance(player)) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </article>
      <!-- ChatWindow -->
      <article class="message box p-0 is-info m-3 has-shadow">
        <p class="message-header">{{ t('articel_chat') }}</p>
        <div class="block p-4">
          <ChatWindow />
          <NewChatForm />
        </div>
      </article>
    </div>

    <!-- Games -->
    <div
      v-if="!loadingTeams && !loadingGames && !users.loggedIn"
      class="column"
    >
      <article class="message box p-0 is-link m-3 has-shadow">
        <div class="message-header">
          {{ t('articel_games') }}
          <button
            v-tooltip="t('show_future')"
            @click="showFutureOnly = !showFutureOnly"
          >
            <i class="material-icons">filter_list</i>
          </button>
        </div>
        <section class="block p-4">
          <div class="b-table">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-narrow is-fullwidth"
                style="table-layout: auto"
              >
                <thead>
                  <tr>
                    <th class="has-text-centered">
                      <span v-tooltip="t('date_of_game')">
                        <i class="material-icons">event</i>
                      </span>
                    </th>
                    <th class="has-text-centered is-hidden-touch">
                      <i class="material-icons">group_work</i>
                    </th>
                    <th class="has-text-right">{{ t('home') }}</th>
                    <th class="is-hidden-mobile"></th>
                    <th class="has-text-centered">
                      <i class="material-icons">sports_soccer</i>
                    </th>
                    <th class="is-hidden-mobile"></th>
                    <th class="has-text-left">{{ t('guest') }}</th>
                    <th class="has-text-centered">{{ t('result') }}</th>
                    <th class="has-text-centered">
                      {{ t('articel_games_myTip') }}
                    </th>
                    <th></th>
                    <th class="has-text-centered">
                      <span v-tooltip="t('articel_games_nr_of_bets-tooltip')">
                        {{ t('articel_games_nr_of_bets') }}</span
                      >
                    </th>
                    <th class="has-text-centered">
                      <span
                        v-tooltip="t('articel_games_win_for_this_game-tooltip')"
                      >
                        {{ t('articel_games_nr_of_wins') }}</span
                      >
                    </th>
                    <th class="has-text-centered">
                      <span
                        v-tooltip="t('articel_games_win_for_this_game-tooltip')"
                      >
                        <i class="material-icons">request_quote</i>
                      </span>
                    </th>
                    <th class="has-text-centered">
                      <span v-tooltip="t('articel_games_balance-tooltip')">
                        <i class="material-icons">account_balance_wallet</i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Table with games -->
                  <tr
                    v-for="game in games"
                    :key="game.id"
                    :class="[
                      game.future
                        ? 'has-background-primary-light'
                        : 'has-background-warning-light',
                      !game.future && !showFutureOnly ? 'is-hidden' : ''
                    ]"
                  >
                    <!-- Date of Game -->
                    <td :data-label="t('articel_games_abbr_date')">
                      {{
                        moment(game.displayDate)
                          .locale($i18n.locale)
                          .format('lll')
                      }}
                    </td>
                    <!-- Group name -->
                    <td
                      :data-label="t('articel_game_group')"
                      class="has-text-centered is-hidden-mobile is-hidden-touch"
                    >
                      {{ game.group }}
                    </td>
                    <!-- Home Teams -->
                    <td :data-label="t('home')" class="has-text-right">
                      <strong>{{ game.home }}</strong>
                    </td>
                    <!-- Flag for Home team -->
                    <td class="has-text-centered is-hidden-mobile">
                      <figure class="image is-32x32">
                        <img :src="getFlagForTeam(game.home)" />
                      </figure>
                    </td>
                    <!-- Divider between teams -->
                    <td class="has-text-centered is-hidden-mobile">:</td>
                    <!-- Flag for Guest team -->
                    <td class="has-text-centered is-hidden-mobile">
                      <figure class="image is-32x32">
                        <img :src="getFlagForTeam(game.guest)" />
                      </figure>
                    </td>
                    <!-- Guest team -->
                    <td :data-label="t('guest')" class="has-text-left">
                      <strong>{{ game.guest }}</strong>
                    </td>
                    <!-- Result -->
                    <td :data-label="t('result')" class="has-text-centered">
                      <span v-if="game.result"
                        >{{ game.result.home }} : {{ game.result.guest }}</span
                      >
                    </td>
                    <!-- my Tip -->
                    <td
                      :data-label="t('articel_games_myTip')"
                      class="has-text-centered"
                    >
                      <div v-if="game.future">
                        <div
                          v-if="!hasCurrentUserAlreadyBet(game.id)"
                          class="field is-horizontal"
                        >
                          <div class="field is-horizontal">
                            <input
                              class="input is-small"
                              type="number"
                              placeholder="0"
                              v-model="tipHome[game.id]"
                            />
                            <span class="mx-1">:</span>
                            <input
                              class="input is-small"
                              type="number"
                              placeholder="0"
                              v-model="tipGuest[game.id]"
                            />
                          </div>
                        </div>
                        <div v-else>
                          <div class="has-text-centered">
                            {{ getCurrentTip(game.id).home }} :
                            {{ getCurrentTip(game.id).guest }}
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="isTipCorrect(game.id)">
                          <div class="has-text-centered has-background-success">
                            {{ getCurrentTip(game.id).home }} :
                            {{ getCurrentTip(game.id).guest }}
                          </div>
                        </div>
                        <div v-else>
                          <div class="has-text-centered has-background-warning">
                            {{ getCurrentTip(game.id).home }} :
                            {{ getCurrentTip(game.id).guest }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <!-- Contols for creating and renewing tips -->
                    <td class="has-text-centered">
                      <div v-if="game.future">
                        <div v-if="!hasCurrentUserAlreadyBet(game.id)">
                          <!-- Checkin result  -->
                          <i
                            v-tooltip="t('check_in_tip')"
                            class="material-icons is-clickable"
                            @click="checkInTip(game.id)"
                            >check_circle
                          </i>
                        </div>
                        <div v-else>
                          <i
                            v-tooltip="t('delete_tip')"
                            class="material-icons is-clickable is-size-4"
                            @click="deleteTip(game.id)"
                            >remove_circle</i
                          >
                        </div>
                      </div>
                    </td>
                    <!-- Number of Tips -->
                    <td
                      :data-label="t('articel_games_nr_of_bets-tooltip')"
                      class="has-text-centered"
                    >
                      <button
                        v-tooltip="t('other_tips')"
                        class="button is-small is-responsive is-rounded is-info"
                        @click="showTips(game.id)"
                      >
                        {{ game.tips.length }}
                      </button>
                    </td>
                    <!-- number of wins -->
                    <td
                      :data-label="t('articel_games_nr_of_wins-tooltip')"
                      class="has-text-centered"
                    >
                      <div
                        :class="
                          hasWinner(game.id) ? 'has-background-success' : ''
                        "
                      >
                        <span v-if="game.result">
                          {{ game.result.numberOfCorrectTips }}
                        </span>
                      </div>
                    </td>
                    <!-- wins for this game-->
                    <td
                      :data-label="t('articel_games_win_for_this_game-tooltip')"
                      class="has-text-centered"
                    >
                      <div
                        :class="
                          isTipCorrect(game.id) ? 'has-background-success' : ''
                        "
                      >
                        <span v-if="game.result">
                          {{
                            getFormattedAmount(game.result.quoteForThisGame)
                          }}</span
                        >
                      </div>
                    </td>
                    <td
                      :data-label="t('articel_games_balance-tooltip')"
                      class="has-text-centered"
                    >
                      <span v-if="game.betData">
                        {{ getFormattedAmount(game.myWin) }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </article>
      <!-- Show tips for game when user selected -->
    </div>

    <!-- Wait until all data loaded -->
    <div v-else class="column is-two-thirds">
      <article class="message box p-0 m-3 has-shadow">
        <div class="box has-text-centered has-background-white-ter">
          <p class="is-size-3">Data Loading ....</p>
          <button
            class="button is-size-1 has-background-white-ter is-loading"
            style="border: none"
          >
            Loading
          </button>
        </div>
      </article>
    </div>

    <!-- Modal -->
    <div v-bind:class="{ 'is-active': showTipsSwitch }" class="modal">
      <div
        class="modal-background"
        @click="showTipsSwitch = !showTipsSwitch"
      ></div>
      <div class="modal-content">
        <!-- Any other Bulma elements you want -->
        <article
          v-if="showTipsSwitch"
          class="message box p-0 is-link m-3 has-shadow"
        >
          <div class="message-header">
            {{ t('games') }}:
            <div>{{ showTipForGame.home }}</div>
            <figure class="image is-32x32">
              <img :src="getFlagForTeam(showTipForGame.home)" />
            </figure>
            <div class="mx-3">:</div>
            <figure class="image is-32x32">
              <img :src="getFlagForTeam(showTipForGame.guest)" />
            </figure>
            <div>{{ showTipForGame.guest }}</div>
            <button class="delete" @click="showTipsSwitch = false"></button>
          </div>
          <div class="block p-4">
            <div class="table-container">
              <table class="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th class="has-text-left">Player</th>
                    <th class="has-text-centered">Tip</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tip in showTipForGame.tips" :key="tip.id">
                    <td class="has-text-left">
                      {{ getNameForUser(tip.user) }}
                    </td>
                    <td class="has-text-centered">
                      {{ tip.home }}
                      :
                      {{ tip.guest }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
      <button
        class="modal-close is-large"
        @click="showTipsSwitch = !showTipsSwitch"
        aria-label="close"
      ></button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

// Team Chat
import NewChatForm from '../components/NewChatForm.vue';
import ChatWindow from '../components/ChatWindow.vue';

import { useI18n } from 'vue-i18n';
import Translations from '@/translations/mainpage.json';

import moment from 'moment';

// firebase imports
import { db } from '@/firebase/config';
import {
  doc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore';

export default {
  created: function () {
    this.moment = moment;
  },
  components: { NewChatForm, ChatWindow },

  setup() {
    // to visualize any error during from processing
    const error = ref(null);

    // Prepare translations
    const { t } = useI18n({
      messages: Translations
    });

    // get Stores
    const user = useUserStore();
    const config = useConfiguraitonStore();

    // loading data
    const loadingGames = ref(true);
    const loadingTeams = ref(true);
    const overallGameData = ref();
    const gameName = ref();

    // Show tips when selected
    const showTipsSwitch = ref(false);
    const showTipForGame = ref('');
    const showFutureOnly = ref(true);

    // New tip
    const tipHome = ref([]);
    const tipGuest = ref([]);

    var myWin = 0;

    // Get Game Name
    gameName.value = config.configuration.GameName;

    // Listener for user data
    const users = ref([]);
    const userRef = query(
      collection(db, 'userCollection'),
      orderBy('betData.amountOfWinForCurrentPlayer', 'desc')
    );
    onSnapshot(
      userRef,
      (snapshot) => {
        users.value = [];
        snapshot.docs.forEach((doc) => {
          let x = users.value.push({ ...doc.data(), id: doc.id }) - 1;
          if (users.value[x].createdAt) {
            users.value[x].createdAt = users.value[x].createdAt
              .toDate()
              .toLocaleDateString('de-DE');
          }
          if (users.value[x].lastLogin) {
            users.value[x].updateAt = users.value[x].lastLogin
              .toDate()
              .toLocaleDateString('de-DE');
          } else {
            users.value[x].updateAt = null;
          }
        });
      },
      (err) => {
        error.value = err;
      }
    );

    // Listener for game data
    const games = ref([]);
    const gameRef = query(
      collection(db, config.configuration.Games),
      orderBy('startsAt')
    );
    delay(1000).then(() => {
      onSnapshot(
        gameRef,
        (snapshot) => {
          games.value = [];
          myWin = 0;
          var actualGame = 0;
          snapshot.docs.forEach((doc) => {
            const tmpDate = doc.data().startsAt.toDate();
            actualGame = games.value.push({
              ...doc.data(),
              id: doc.id,
              displayDate: tmpDate,
              future: tmpDate > new Date() ? true : false,
              myWin: 0
            });
            // calculate balance for current player
            if (doc.data().betData) {
              if (doc.data().tips.length > 0) {
                if (
                  doc.data().tips.find((tip) => {
                    return tip.user === user.uid;
                  })
                ) {
                  myWin -= doc.data().betData.singleBet;
                  if (isTipCorrect(doc.id)) {
                    myWin += doc.data().result.quoteForThisGame;
                    myWin += doc.data().betData.potPayout;
                  }
                }
              }
            }
            games.value[actualGame - 1]['myWin'] = myWin;
            loadingGames.value = false;
          });
        },
        (err) => {
          error.value = err;
        }
      );
    });

    // Listener for overall game data
    const overallGameRef = doc(
      db,
      'betCollection',
      config.configuration.betData
    );
    onSnapshot(
      overallGameRef,
      (snapshot) => {
        overallGameData.value = {
          totalTips: snapshot.data().totalTips,
          totalGames: snapshot.data().totalGames,
          totalPayinBalance: snapshot.data().totalPayinBalance,
          totalPayoutBalance: snapshot.data().totalPayoutBalance,
          totalOpenBalance: snapshot.data().totalOpenBalance,
          totalOpenGames: snapshot.data().totalOpenGames,
          totalPayoutBalance: snapshot.data().totalPayoutBalance,
          totalPayoutGames: snapshot.data().totalPayoutGames
        };
      },
      (err) => {
        error.value = err;
      }
    );

    // Read teams
    const teams = ref([]);
    const teamRef = collection(db, config.configuration.Teams);
    getDocs(teamRef)
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          let x = teams.value.push({ ...doc.data(), id: doc.id }) - 1;
        });
        loadingTeams.value = false;
      })
      .catch((err) => {
        error.value = err;
      });

    // new Tip
    const checkInTip = (gameID) => {
      const searchGame = games.value.find((game) => game.id == gameID);
      if (hasCurrentUserAlreadyBet(gameID)) {
        error.value = 'You bet already for this game!';
      } else {
        if (searchGame.startsAt.toDate() < new Date()) {
          error.value = t('too_late');
        } else {
          let docRef = doc(db, config.configuration.Games, gameID);
          searchGame.tips.push({
            user: user.uid,
            home: tipHome.value[gameID],
            guest: tipGuest.value[gameID],
            win: 0
          });
          updateDoc(docRef, searchGame)
            .then(() => {
              tipHome.value = [];
              tipGuest.value = [];
            })
            .catch((err) => {
              error.value = err;
            });
        }
      }
    };

    // delete Tip
    const deleteTip = (gameID) => {
      const searchGame = games.value.find((game) => game.id == gameID);
      if (!hasCurrentUserAlreadyBet(gameID)) {
        error.value('There is no tip for current user at current game');
      } else {
        let docRef = doc(db, config.configuration.Games, gameID);
        const indexOfObject = searchGame.tips.findIndex((object) => {
          return object.user === user.uid;
        });
        searchGame.tips.splice(indexOfObject, 1);
        updateDoc(docRef, searchGame)
          .then(() => {
            console.log('Tip successful updated');
          })
          .catch((err) => {
            error.value = err;
          });
      }
    };

    function delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    // Get URL Flag
    function getFlagForTeam(currentTeam) {
      const searchObject = teams.value.find((team) => team.name == currentTeam);
      if (!searchObject) {
        return '';
      }

      return searchObject.flag;
    }

    // Check if current user already bet
    function hasCurrentUserAlreadyBet(currentGame) {
      var searchUserTip = '';
      const searchGame = games.value.find((game) => game.id == currentGame);
      if (searchGame) {
        if (searchGame.tips.length > 0) {
          searchUserTip = searchGame.tips.find((tip) => {
            return tip.user == user.uid;
          });
        }
      }
      if (searchUserTip) {
        return true;
      } else {
        return false;
      }
    }

    // read tips for current user and current game
    const getCurrentTip = (gameID) => {
      var currentTip = [{ home: '', guest: '' }];
      var searchGame = games.value.find((game) => game.id == gameID);
      if (searchGame) {
        const searchTip = searchGame.tips.find((tip) => tip.user == user.uid);
        if (searchTip) {
          currentTip = searchTip;
        }
      }
      return currentTip;
    };

    const isTipCorrect = (gameID) => {
      var match = false;
      var searchGame = games.value.find((game) => game.id == gameID);
      if (searchGame) {
        if (searchGame.result) {
          const searchTip = searchGame.tips.find((tip) => tip.user == user.uid);
          if (searchTip) {
            if (
              (searchTip.home == searchGame.result.home) &
              (searchTip.guest == searchGame.result.guest)
            )
              return true;
          }
        }
      }
      return match;
    };

    const getFormattedAmount = (amount) => {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(amount);
    };

    const isAmountPositiv = (amount) => {
      if (amount >= 0) {
        return true;
      } else {
        return false;
      }
    };

    const calculateBalance = (player) => {
      let balance = player.betData.balanceForCurrentPlayer;
      if (player.champion) {
        balance -= 1;
      }
      return balance;
    };

    const hasWinner = (gameID) => {
      var searchGame = games.value.find((game) => game.id == gameID);
      var result = false;
      if (searchGame.result) {
        if (searchGame.result.numberOfCorrectTips > 0) {
          result = true;
        }
        return result;
      }
    };

    const showTips = (gameID) => {
      showTipsSwitch.value = !showTipsSwitch.value;
      showTipForGame.value = games.value.find((game) => game.id == gameID);
    };

    const getNameForUser = (userID) => {
      return users.value.find((user) => user.id == userID).displayName;
    };

    const championSelect = (event) => {
      console.log(event);
      console.log(user.champion);
      console.log(user.uid);
      try {
        let docRef = doc(db, 'userCollection', user.uid);
        updateDoc(docRef, {
          champion: user.champion
        });
      } catch (err) {
        error.value = err;
      }
    };

    return {
      gameName,
      error,
      user,
      users,
      games,
      teams,
      loadingTeams,
      loadingGames,
      tipHome,
      tipGuest,
      overallGameData,
      showTipsSwitch,
      showTipForGame,
      showFutureOnly,
      config,
      showTips,
      getCurrentTip,
      checkInTip,
      hasWinner,
      deleteTip,
      getFlagForTeam,
      hasCurrentUserAlreadyBet,
      isTipCorrect,
      getFormattedAmount,
      isAmountPositiv,
      getNameForUser,
      championSelect,
      calculateBalance,
      t
    };
  }
};
</script>

<style></style>
