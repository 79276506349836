<template>
  <div class="block mx-4 mt-4">
    <!-- error message -->
    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>
    <!-- Create Game -->
    <article class="message box p-0 is-primary has-shadow">
      <div class="message-header">
        <p>Create Game</p>
        <button
          v-if="showCreateGame == true"
          class="delete"
          aria-label="delete"
          @click="showCreateGame = false"
        ></button>
        <button
          v-if="showCreateGame == false"
          class="open"
          aria-label="open"
          @click="showCreateGame = true"
        ></button>
      </div>
      <div v-if="showCreateGame == true" class="block p-4">
        <form class="message is-primary">
          <div class="field is-horizontal has-addons has-addons-centered">
            <!-- Group -->
            <div class="field">
              <div class="control">
                <label class="label">Group </label>
                <div class="select">
                  <select v-model="newGame.group">
                    <option v-for="group in groups" :key="group.id">
                      {{ group }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- Date -->
            <div class="field ml-6 mr-3">
              <label class="label">Date</label>
              <div class="control">
                <input
                  class="input"
                  type="datetime-local"
                  placeholder="Date of the game"
                  v-model="newGame.date"
                />
              </div>
            </div>
          </div>

          <div class="field is-horizontal has-addons has-addons-centered">
            <div class="field has-addons has-addons-centered">
              <p class="control">
                <a class="button is-primary"> Home </a>
              </p>
              <div class="select">
                <select v-model="newGame.home">
                  <option v-for="team in teams" :key="team.id">
                    {{ team.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field mx-3">
              <p class="is-size-3">:</p>
            </div>
            <div class="field has-addons has-addons-centered">
              <div class="control">
                <div class="select">
                  <select v-model="newGame.guest">
                    <option v-for="team in teams" :key="team.id">
                      {{ team.name }}
                    </option>
                  </select>
                </div>
              </div>
              <p class="control">
                <a class="button is-primary"> Guest </a>
              </p>
            </div>
          </div>

          <!-- Create new game  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-primary" @click="handleNewGame">
                Create new game
              </a>
            </p>
          </div>
        </form>
      </div>
    </article>
    <!-- List of Games -->
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Games</p>
      <div class="block p-4">
        <section id="my-table">
          <div class="table-container">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Group</th>
                  <th>Home Team</th>
                  <th>Guest Team</th>
                  <th class="has-text-centered">#tips</th>
                  <th class="has-text-centered">Pay in</th>
                  <th class="has-text-centered">Result</th>
                  <th class="has-text-centered">#CorrectTips</th>
                  <th class="has-text-centered">Quote</th>
                  <th class="has-text-centered">Pay out</th>
                  <th class="has-text-centered">Pot</th>

                  <th class="has-text-right">Controls</th>
                </tr>
              </thead>
              <tbody>
                <!-- Liste -->
                <tr v-for="game in games" :key="game.id">
                  <td>
                    {{
                      moment(game.displayDate)
                        .locale($i18n.locale)
                        .format('lll')
                    }}
                  </td>
                  <td>{{ game.group }}</td>
                  <td>{{ game.home }}</td>
                  <td>{{ game.guest }}</td>
                  <td class="has-text-centered">{{ game.tips.length }}</td>
                  <td class="has-text-centered">
                    <span v-if="game.result">{{
                      getFormattedAmount(game.betData.betPayin)
                    }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="game.result"
                      >{{ game.result.home }} : {{ game.result.guest }}</span
                    >
                  </td>
                  <td class="has-text-centered">
                    <span v-if="game.result">{{
                      game.result.numberOfCorrectTips
                    }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="game.result">
                      {{
                        getFormattedAmount(game.result.quoteForThisGame)
                      }}</span
                    >
                  </td>
                  <td class="has-text-centered">
                    <span v-if="game.result">{{
                      getFormattedAmount(game.betData.betPayout)
                    }}</span>
                  </td>
                  <td class="has-text-centered">
                    <span v-if="game.result">{{
                      getFormattedAmount(game.betData.pot)
                    }}</span>
                  </td>
                  <!-- Contols -->
                  <td class="has-text-right">
                    <i
                      v-if="!game.future"
                      @click="setResult(game.id)"
                      class="material-icons is-warning button mx-2"
                      >done</i
                    >
                    <i
                      v-if="game.future"
                      @click="editGame(game.id)"
                      class="material-icons is-info button mx-2"
                      >edit</i
                    >
                    <i
                      @click="deleteGame(game.id)"
                      class="material-icons is-danger button mx-2"
                      >delete</i
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </article>
    <!-- Edit game -->
    <article
      v-if="selectedGame"
      class="message box p-0 is-primary my-6 has-shadow"
    >
      <p class="message-header">Edit game</p>
      <div class="block p-4">
        <form class="message is-primary">
          <div class="field is-horizontal has-addons has-addons-centered">
            <!-- Group -->
            <div class="field">
              <div class="control">
                <label class="label">Group </label>
                <div class="select">
                  <select v-model="selectedGameData.group">
                    <option v-for="group in groups" :key="group.id">
                      {{ group }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- Date -->
            <div class="field ml-6 mr-3">
              <label class="label">Date</label>
              <div class="control">
                <input
                  class="input"
                  type="datetime-local"
                  placeholder="Date of the game"
                  v-model="selectedGameData.date"
                />
              </div>
            </div>
          </div>

          <div class="field is-horizontal has-addons has-addons-centered">
            <div class="field has-addons has-addons-centered">
              <p class="control">
                <a class="button is-primary"> Home </a>
              </p>
              <div class="select">
                <select v-model="selectedGameData.home">
                  <option v-for="team in teams" :key="team.id">
                    {{ team.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="field mx-3">
              <p class="is-size-3">:</p>
            </div>
            <div class="field has-addons has-addons-centered">
              <div class="control">
                <div class="select">
                  <select v-model="selectedGameData.guest">
                    <option v-for="team in teams" :key="team.id">
                      {{ team.name }}
                    </option>
                  </select>
                </div>
              </div>
              <p class="control">
                <a class="button is-primary"> Guest </a>
              </p>
            </div>
          </div>

          <!-- Update  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-primary" @click="updateGame"> Update game </a>
            </p>
          </div>
        </form>
      </div>
    </article>
    <!-- Set result for a game -->
    <article
      v-if="showFinalizeGame"
      class="message box p-0 is-primary my-6 has-shadow"
    >
      <p class="message-header">Set result</p>
      <div class="block p-4">
        <form class="message is-primary">
          <div class="field is-horizontal has-addons has-addons-centered">
            <!-- Date -->
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="field-label">Date</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      disabled
                      class="input"
                      type="datetime-local"
                      v-model="showFinalizeGameData.date"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal has-addons has-addons-centered">
            <div class="field has-addons has-addons-centered">
              <p class="control">
                <a class="button is-primary">
                  <figure class="image is-32x32 mx-2">
                    <img :src="getFlagForTeam(showFinalizeGameData.home)" />
                  </figure>
                  <div>{{ showFinalizeGameData.home }}</div>
                </a>
              </p>
              <div class="control">
                <input class="input" type="number" v-model="result.home" />
              </div>
            </div>
            <div class="field mx-3">
              <p class="is-size-3">:</p>
            </div>
            <div class="field has-addons has-addons-centered">
              <div class="control">
                <div class="control">
                  <input class="input" type="number" v-model="result.guest" />
                </div>
              </div>
              <p class="control">
                <a class="button is-primary">
                  {{ showFinalizeGameData.guest }}
                  <figure class="image is-32x32 mx-2">
                    <img :src="getFlagForTeam(showFinalizeGameData.guest)" />
                  </figure>
                </a>
              </p>
            </div>
          </div>

          <!-- Update  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-primary" @click="finalizeGame">
                Set result
              </a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </div>
</template>

<script>
// vue imports
import { ref } from 'vue';

// firebase imports
import { db, storage } from '@/firebase/config';
import {
  collection,
  doc,
  onSnapshot,
  query,
  getDocs,
  updateDoc,
  deleteDoc,
  addDoc,
  Timestamp,
  serverTimestamp,
  orderBy
} from 'firebase/firestore';
import { ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import moment from 'moment';

export default {
  created: function () {
    this.moment = moment;
  },
  setup() {
    // to visualize any error during from processing
    const error = ref(null);

    // get Stores
    const config = useConfiguraitonStore();

    // Edit controls
    const showCreateGame = ref(false);
    const showFinalizeGame = ref('');

    // All data for editing
    const games = ref([]);
    const result = ref([]);
    const selectedGame = ref('');
    const selectedGameData = ref('');
    const showFinalizeGameData = ref('');

    // All teams read from firebase
    const teams = ref([]);

    // All flags read from storage
    const flags = ref([]);

    // New game
    const newGame = ref([{ group: '', home: '', guest: '', date: '' }]);

    // Get Groups
    const groups = ref([]);
    groups.value = config.configuration.GroupNames;

    // read flags - read all entries from storage and get their URL
    console.log('Admin Games - read flags');
    const listRef = storageRef(storage, config.configuration.Flags);
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef)
            .then((url) => {
              flags.value.push({ url: url });
            })
            .catch((err) => {
              error.value = err;
            });
        });
      })
      .catch((err) => {
        error.value = err;
      });

    // Read teams
    console.log('Admin Games - read teams');
    const teamRef = collection(db, config.configuration.Teams);
    const q = query(teamRef, orderBy('name'));
    getDocs(q)
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          let x = teams.value.push({ ...doc.data(), id: doc.id }) - 1;
        });
      })
      .catch((err) => {
        error.value = err;
      });

    // Read game data
    console.log('Admin Games - read games');
    const gameRef = query(
      collection(db, config.configuration.Games),
      orderBy('startsAt')
    );
    onSnapshot(
      gameRef,
      (snapshot) => {
        games.value = [];
        snapshot.docs.forEach((doc) => {
          games.value.push({
            ...doc.data(),
            id: doc.id,
            displayDate: doc.data().startsAt.toDate(),
            future: doc.data().startsAt.toDate() > new Date() ? true : false
          });
        });
      },
      (err) => {
        error.value = err;
      }
    );

    // Edit game
    const editGame = (idOfSelectedGame) => {
      console.log('Admin Games - edit Game: ', idOfSelectedGame);
      selectedGameData.value = games.value.find(
        ({ id }) => id == idOfSelectedGame
      );
      selectedGame.value = selectedGameData.value.id;
      showFinalizeGame.value = '';
      console.log(selectedGame.value);
    };

    // Delete game
    const deleteGame = (idOfSelectedGame) => {
      console.log('Delete Game: ', idOfSelectedGame);
      let docRef = doc(db, config.configuration.Games, idOfSelectedGame);
      deleteDoc(docRef)
        .then(() => {
          selectedGame.value = '';
        })
        .catch((err) => {
          error.value = err;
        });
    };

    // Create new Game
    const handleNewGame = () => {
      console.log('Create new game');
      console.dir(newGame.value);

      if (!newGame.value.group) {
        error.value = 'Group required';
      } else if (!newGame.value.date) {
        error.value = 'Date required';
      } else if (!newGame.value.home) {
        error.value = 'Home team required';
      } else if (!newGame.value.guest) {
        error.value = 'Guest team required';
      } else {
        const docRef = addDoc(collection(db, config.configuration.Games), {
          group: newGame.value.group,
          home: newGame.value.home,
          guest: newGame.value.guest,
          date: newGame.value.date,
          tips: [],
          startsAt: new Timestamp(
            Math.round(new Date(newGame.value.date) / 1000),
            83000000
          ),
          createdAt: serverTimestamp()
        })
          .then(() => {
            newGame.value = [];
          })
          .catch((err) => {
            error.value = err;
          });
      }
    };

    // Update game data
    const updateGame = () => {
      let docRef = doc(
        db,
        config.configuration.Games,
        selectedGameData.value.id
      );

      updateDoc(docRef, {
        group: selectedGameData.value.group,
        home: selectedGameData.value.home,
        guest: selectedGameData.value.guest,
        date: selectedGameData.value.date,
        startsAt: new Timestamp(
          Math.round(new Date(selectedGameData.value.date) / 1000),
          83000000
        ),
        updatedAt: serverTimestamp()
      })
        .then(() => {
          selectedGame.value = '';
        })
        .catch((err) => {
          error.value = err;
        });
    };

    // Show form to edit result
    const setResult = (idOfSelectedGame) => {
      console.log('Set Result for game: ' + idOfSelectedGame);
      selectedGame.value = '';
      showFinalizeGame.value = idOfSelectedGame;
      showFinalizeGameData.value = games.value.find(
        ({ id }) => id == idOfSelectedGame
      );
    };

    // Finalize Game - set result and calculage wins
    const finalizeGame = async () => {
      console.log('Make Game final: ' + showFinalizeGame);

      // 1. set result for current game
      const resUpdateResultForCurrentGame = await updateResultForCurrentGame();
      console.log(
        'updateResultForCurrentGame: ' + resUpdateResultForCurrentGame
      );

      // 2. updating all games and set pot
      const resUpdateBetData = await updateBetData();
      console.log('updateBetData completed: ' + resUpdateBetData);

      // 3. updateing player data with finalized games
      const resUpdatePlayerData = await updatePlayerData();
      console.log('updatePlayerData completed with: ' + resUpdatePlayerData);

      // 4. calculate over game data
      const resUpdateGameData = await updateGameData();
      console.log('updatePlayerData completed with: ' + resUpdateGameData);

      console.log('Make Game final: all updates completed');
    };

    const updateResultForCurrentGame = async () => {
      // 1. set result for current game
      console.log('updateResultForCurrentGame started');
      var updates = 0;

      showFinalizeGameData.value.result = {
        home: result.value.home,
        guest: result.value.guest
      };
      // identiy winners
      var numberOfTips = 0;
      var numberOfCorrectTips = 0;
      var quoteForThisGame = 0;

      // 1. set result for current game
      showFinalizeGameData.value.tips.forEach((item) => {
        // count tips
        numberOfTips++;
        // count correct tips
        if (
          (item.home == result.value.home) &
          (item.guest == result.value.guest)
        ) {
          numberOfCorrectTips++;
        }
      });
      // set result
      if (numberOfCorrectTips > 0) {
        quoteForThisGame =
          (numberOfTips * config.configuration.betValue) / numberOfCorrectTips;
      } else {
        quoteForThisGame = 0;
      }

      showFinalizeGameData.value.result = {
        home: result.value.home,
        guest: result.value.guest,
        numberOfTips: numberOfTips,
        numberOfCorrectTips: numberOfCorrectTips,
        quoteForThisGame: quoteForThisGame
      };

      try {
        await updateDoc(
          doc(db, config.configuration.Games, showFinalizeGame.value),
          showFinalizeGameData.value
        );
        updates++;
        showFinalizeGame.value = '';
        showFinalizeGameData.value = '';
      } catch (err) {
        error.value = err;
      }
      return updates;
    };

    const updateBetData = async () => {
      // 2. updating all games and set pot
      var pot = 0;
      var betPayin = 0;
      var betPayout = 0;
      var potPayout = 0;

      console.log('updateBetData started');

      var updates = 0;
      games.value.forEach((game) => {
        if (game.result) {
          // payment for this game
          betPayin = game.result.numberOfTips * config.configuration.betValue;
          if (game.result.numberOfCorrectTips > 0) {
            // payout for this game
            potPayout = pot / game.result.numberOfCorrectTips;
            betPayout =
              game.result.numberOfCorrectTips * game.result.quoteForThisGame +
              pot;
            pot = 0;
          } else {
            betPayout = 0;
            potPayout = 0;
            pot += betPayin - betPayout;
          }
          const docRef = doc(db, config.configuration.Games, game.id);
          try {
            const res = updateDoc(docRef, {
              betData: {
                pot: pot,
                potPayout: potPayout,
                betPayin: betPayin,
                betPayout: betPayout,
                singleBet: config.configuration.betValue
              }
            });
            updates++;
          } catch (err) {
            error.value = err;
          }
        }
      });
      return updates;
    };

    const updatePlayerData = async () => {
      console.log('updatePlayerData started');
      var updates = 0;
      var users = [];
      try {
        const querySnapshot = await getDocs(collection(db, 'userCollection'));
        querySnapshot.docs.forEach((item) => {
          let x = users.push({ ...item.data(), id: item.id }) - 1;
          var numberOfBetsForCurrentPlayer = 0;
          var numberOfCorrectTipsForCurrentPlayer = 0;
          var amountOfWinForCurrentPlayer = 0;
          var amountOfBets = 0;
          var balanceForCurrentPlayer = 0;
          // Loop for each game
          games.value.forEach((currentGame) => {
            // check if game has tips
            if (currentGame.hasOwnProperty('tips')) {
              const userTip = currentGame.tips.find(
                (tip) => tip.user == users[x].id
              );
              // if current user tipped the current game
              if (userTip) {
                // Check if result is available for current game
                if (currentGame.hasOwnProperty('result')) {
                  numberOfBetsForCurrentPlayer =
                    numberOfBetsForCurrentPlayer + 1;
                  if (userTip) {
                    amountOfBets += currentGame.betData.singleBet;
                    if (
                      (userTip.home == currentGame.result.home) &
                      (userTip.guest == currentGame.result.guest)
                    ) {
                      numberOfCorrectTipsForCurrentPlayer++;
                      amountOfWinForCurrentPlayer +=
                        currentGame.result.quoteForThisGame;
                      amountOfWinForCurrentPlayer +=
                        currentGame.betData.potPayout;
                    }
                  }
                }
              }
            }
            updates++;
          });
          balanceForCurrentPlayer = amountOfWinForCurrentPlayer - amountOfBets;
          try {
            let docRef = doc(db, 'userCollection', users[x].id);
            updateDoc(docRef, {
              betData: {
                numberOfBetsForCurrentPlayer: numberOfBetsForCurrentPlayer,
                numberOfCorrectTipsForCurrentPlayer:
                  numberOfCorrectTipsForCurrentPlayer,
                amountOfWinForCurrentPlayer: amountOfWinForCurrentPlayer,
                amountOfBets: amountOfBets,
                balanceForCurrentPlayer: balanceForCurrentPlayer
              }
            });
            updates++;
          } catch (err) {
            error.value = err;
          }
        });
      } catch (err) {
        error.value = err;
      }
      return updates;
    };

    const updateGameData = async () => {
      console.log('updateGameData started');
      var updates = 0;
      var totalTips = 0;
      var totalGames = 0;
      var totalPayinBalance = 0;
      var totalPayoutBalance = 0;
      var totalOpenBalance = 0;
      var totalOpenGames = 0;
      var totalPayoutBalance = 0;
      var totalPayoutGames = 0;
      // Loop for each game
      games.value.forEach((currentGame) => {
        updates++;
        // number of tips overall
        if (currentGame.hasOwnProperty('betData')) {
          totalTips += currentGame.tips.length;
          totalGames += 1;
          // calculate pot
          if (currentGame.betData.pot == 0) {
            totalOpenBalance = 0;
            totalOpenGames = 0;
          } else {
            totalOpenBalance = currentGame.betData.pot;
            totalOpenGames += 1;
          }
          // Calculate what has been payedout
          if (currentGame.betData.betPayout > 0) {
            totalPayoutBalance += currentGame.betData.betPayout;
            totalPayoutGames += 1;
          }
          if (currentGame.betData.betPayin > 0) {
            totalPayinBalance += currentGame.betData.betPayin;
          }
        }
      });
      // Update overall game data
      try {
        const docRef = doc(db, 'betCollection', config.configuration.betData);
        updateDoc(docRef, {
          totalTips: totalTips,
          totalGames: totalGames,
          totalPayinBalance: totalPayinBalance,
          totalPayoutBalance: totalPayoutBalance,
          totalOpenBalance: totalOpenBalance,
          totalOpenGames: totalOpenGames,
          totalPayoutBalance: totalPayoutBalance,
          totalPayoutGames: totalPayoutGames
        });
      } catch (err) {
        error.value = err;
      }

      return updates;
    };

    // Get URL Flag
    function getFlagForTeam(currentTeam) {
      const searchObject = teams.value.find((team) => team.name == currentTeam);
      return searchObject.flag;
    }

    const getFormattedAmount = (amount) => {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(amount);
    };

    return {
      showCreateGame,
      showFinalizeGame,
      showFinalizeGameData,
      result,
      flags,
      teams,
      games,
      groups,
      selectedGame,
      selectedGameData,
      newGame,
      error,
      editGame,
      deleteGame,
      updateGame,
      handleNewGame,
      setResult,
      finalizeGame,
      getFlagForTeam,
      getFormattedAmount
    };
  }
};
</script>

<style scoped>
#my-table .table {
  background-color: whitesmoke;
}
</style>
