import { defineStore } from "pinia";
import { ref } from 'vue'

import { db } from '@/firebase/config';
import {
    doc,
    getDoc
} from 'firebase/firestore';


export const useConfiguraitonStore = defineStore({
    id: "configuration",
    state: () => ({
        configuration: [],
        loading: true,
    }),
    actions: {
        getConfiguration() {
            if (this.configuration.length !== 0) {
                return;
            }
            this.loading = true;
            this.configuration = [];
            try {
                getDoc(doc(db, 'betCollection', "configuration"))
                    .then((snapshot) => {
                        this.configuration = snapshot.data();
                        this.loading = false;

                    })
            } catch (error) {
                console.log(error);

            }
        },
    },
});