import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'


// firebase imports for auth
import { auth } from '@/firebase/config';
import {
    onAuthStateChanged,
} from 'firebase/auth'

import App from './App.vue'
import router from './router'

// Import Bulma
require('@/assets/main.scss');
// import './../node_modules/bulma/css/bulma.css'

// Prepare Pinia 
const pinia = createPinia();

// Language support
const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: navigator.language, // set locale
    fallbackLocale: 'de', // set fallback locale
})

// tooltip plugin
import VTooltipPlugin from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'

let app;

onAuthStateChanged(auth, () => {
    if (!app) {
        app = createApp(App);

        app.use(pinia);
        app.use(router);
        app.use(i18n);
        app.use(VTooltipPlugin);

        app.mount("#app");
    }
});